<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 관리">
            - 회사소개 사이트의 CEO메세지를 관리할 수 있습니다<br><br>
            * CARROT HUB : https://carrotglobal.com/<br>
            * 회사소개 : https://www.carrotglobal.com/carrot/index.html<br>
            * CTM HQ 매니저단 : https://ctm.carrotenglish.kr/landing/home<br>
            * CTM 강사단 : https://tctm.carrotenglish.kr/landing/home<br>
            * ITM HQ 매니저단 : https://hq.carrotenglish.net/
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">등록일</th>
                                <td>
                                    <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker> 
                                    ~ 
                                    <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="bbs.doInit" class="btn-default float-right mt-10">검색</button>
                    <div class="clear"></div>

                    <div class="mt-40 mb-20">                        
                        <button v-if="bbs.isAuth==true" @click="bbs.addMagazine" class="btn-default float-right">사보 등록하기</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="200">
                            <col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>사보명</th>
                                <th>제목</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.topfix=='Y'?'-':irow.num }}</td>
                                <td>{{ irow.magazine_name }}</td>
                                <td class="txt-left" @click="bbs.showView(irow.idx)">
                                    <span class="btn-view">{{ irow.title }}</span>
                                    <span class="list-num ml-5" v-if="irow.cnt_cmt>0">({{ irow.cnt_cmt }})</span>
                                    <span class="ico-n ml-5" v-if="irow.is_new=='Y'">NEW</span>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="3">등록된 사보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store  = useStore();

        const bbs = reactive({
            isAuth    : false,
            isMounted : false,
            isAll : false,
            selected : [],
            page : 1,
            rows : 50,
            sdate: "",
            edate: "",
            list : [], total : 0,

            checkAll : () => {
                if( bbs.isAll == true ){
                    for(let i=0; i<bbs.list.length; i++){
                        let irow = bbs.list[i];
                        bbs.selected.push(irow.idx);
                    }
                } else {
                    bbs.isAll    = false;
                    bbs.selected = [];
                }
            },

            doDelete : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '삭제',
                        text  : '삭제할 사보를 선택하세요.'
                    });
                    return;
                }

                Swal.fire({
                    title : '삭제',
                    text  : '총 ' + bbs.selected.length + '건의 사보를 삭제하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : bbs.selected
                        };
                        axios.post("/rest/resourceManagement/delMagazines", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("삭제하였습니다.");
                                bbs.doInit();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                })
            },
            showView: (idx)=>{
                // location.href = "NoticeView/" + idx;
                router.push({
                    name   : 'ResourceManagement-MagazineManagementView-idx',
                    params : { idx:idx }
                });
            },

            addMagazine : () => {
                sessionStorage.setItem('MagazineManagementList', JSON.stringify({
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate     : bbs.sdate,
                    edate     : bbs.edate,
                }));
                router.push({
                    name : 'ResourceManagement-MagazineManagementAdd'
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.isAll = false;
                bbs.selected = [];
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {                    
                    page  : bbs.page,
                    sdate : bbs.sdate,
                    edate : bbs.edate,
                    rows : bbs.rows,
                };
                axios.get('/rest/resourceManagement/getMagazineList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAll = false;
                        bbs.selected = [];
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },


        });


        onMounted(() => {
            // Mounted
            if( store.state.isDevelopmentTeam == false ) {
                router.go(-1);
                Swal.fire({
                    title : '사보 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }else{
                bbs.isAuth = true;
            }

            bbs.isMounted = true;

            let ss = sessionStorage.getItem('MagazineManagementList');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.page ) bbs.page = ss.page;
                if( ss.rows ) bbs.rows = ss.rows;
                if( ss.sdate     ) bbs.sdate     = ss.sdate;
                if( ss.edate     ) bbs.edate     = ss.edate;
                if( ss.title     ) bbs.title     = ss.title;
                if( ss.writer     ) bbs.writer     = ss.writer;
            }

            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>